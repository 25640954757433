import { RouterOutputs } from "./trpc";

export const sortNameList = (
  list: RouterOutputs["adminRouter"]["getEmployees"],
): RouterOutputs["adminRouter"]["getEmployees"] => {
  return list.sort((a, b) => {
    if (!a.first_name) return 1;
    if (!b.first_name) return -1;
    if (a.first_name < b.first_name) return -1;
    if (a.first_name > b.first_name) return 1;
    return 0;
  });
};
