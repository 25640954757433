import { AutoFillOrganizationSchema } from "./CreateOrganizationAuto";
import { useEffect, useState } from "react";
import { z } from "zod";
import ManualFillOrganizationInfo from "../components/ManualFillForm";
import { useToast } from "@chakra-ui/react";

export function CreateOrganizationManual() {
  const [data, setData] = useState<z.infer<
    typeof AutoFillOrganizationSchema
  > | null>();
  const toast = useToast();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const fromLocalStorage = localStorage.getItem(
      "create-organization-parial-data",
    );
    if (fromLocalStorage) {
      try {
        const parsed = AutoFillOrganizationSchema.parse(
          JSON.parse(fromLocalStorage),
        );
        setData(parsed);
      } catch (error) {
        toast({
          title: "Kunne ikke fylle ut automatisk",
          description: "Vennligst fyll ut manuelt",
          status: "warning",
        });
      }
    }
    setChecked(true);
    return () => {
      localStorage.removeItem("create-organization-parial-data");
    };
  }, [toast]);

  if (!checked) return null;

  if (!data) return <ManualFillOrganizationInfo />;

  return <ManualFillOrganizationInfo data={data} />;
}
