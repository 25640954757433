import { getParamLink, links } from "@timetable/server/src/utils/Links";
import { ErrorPage } from "components/ErrorPage";
import {
  AdminPanel,
  ApproveHours,
  AttachmentsPage,
  CompanySettings,
  InternalAttachments,
  InvitationsTable,
  RegisteredHoursOverview,
  TimesheetLog,
} from "features/admin";
import { EmployeesTable } from "features/admin/components/company/EmployeesTable";
import { EditEmployee } from "features/admin/pages/EditEmployee";
import { Login } from "features/auth";
import { LoginWithOtp } from "features/auth/pages/LoginWithOtp";
import { AdminOnboardingLayout } from "features/onboarding/admin/AdminOnboardingLayout";
import { ChoosePlan } from "features/onboarding/admin/choosePlan/pages/ChoosePlan";
import { CompleteOnboarding } from "features/onboarding/admin/completeProcess/pages/CompleteOnboarding";
import { CreateOrganizationAuto } from "features/onboarding/admin/createOrganization/pages/CreateOrganizationAuto";
import { CreateOrganizationManual } from "features/onboarding/admin/createOrganization/pages/CreateOrganizationManual";
import { InviteEmployeesPage } from "features/onboarding/admin/inviteEmployees/pages/InviteEmployeePage";
import { StartAdminOnboarding } from "features/onboarding/admin/startProcess/pages/StartAdminOnboarding";
import { ApplyInvite } from "features/onboarding/user/pages/ApplyInvite";
import { CreateProfile } from "features/onboarding/user/pages/CreateProfile";
import { InitialTeamSelect } from "features/team";
import { MyHours } from "features/timesheet";
import {
  EditHourEntryPage,
  NewHourEntryPage,
} from "features/timesheet/pages/NewHourEntryPage";
import { Profile } from "features/user/pages/Profile";
import { NewInternalUtlegg, UserUtleggOverview } from "features/utlegg";
import { UtleggDetail } from "features/utlegg/pages/UtleggDetails";
import { DashboardLayout } from "layout/DashboardLayout";
import { Dashboard404, DashboardPageError, Onboarding404 } from "layout/Error";
import { createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: links.login,
    element: <Login />,
  },
  {
    path: links.applyInvite,
    element: <ApplyInvite />,
  },
  {
    path: getParamLink("loginWithOtp"),
    element: <LoginWithOtp />,
  },
  {
    path: links.selectedTeam,
    element: <InitialTeamSelect />,
  },
  {
    path: "/onboarding",
    children: [
      {
        path: "/onboarding/*",
        element: <Onboarding404 />,
      },
      {
        path: links.onboarding_user_profile,
        element: <CreateProfile />,
      },
      {
        element: <AdminOnboardingLayout />,
        children: [
          {
            path: links.admin_onboarding_start,
            element: <StartAdminOnboarding />,
            errorElement: <DashboardPageError />,
          },
          {
            path: links.admin_onboarding_create_organization_auto,
            element: <CreateOrganizationAuto />,
            errorElement: <DashboardPageError />,
          },
          {
            path: links.admin_onboarding_create_organization_manual,
            element: <CreateOrganizationManual />,
            errorElement: <DashboardPageError />,
          },
          {
            path: getParamLink("admin_onboarding_choose_plan"),
            element: <ChoosePlan />,
            errorElement: <DashboardPageError />,
          },
          {
            path: getParamLink("admin_onboarding_invite_employees"),
            element: <InviteEmployeesPage />,
            errorElement: <DashboardPageError />,
          },
          {
            path: getParamLink("admin_onboarding_finish"),
            element: <CompleteOnboarding />,
            errorElement: <DashboardPageError />,
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/dashboard/*",
        element: <Dashboard404 />,
      },
      {
        path: links.profile,
        element: <Profile />,
        errorElement: <DashboardPageError />,
      },
      {
        path: links.timesheet,
        element: <MyHours />,
        errorElement: <DashboardPageError />,
      },
      {
        path: links.timesheetNew,
        element: <NewHourEntryPage />,
        errorElement: <DashboardPageError />,
      },
      {
        path: getParamLink("timesheet_edit"),
        element: <EditHourEntryPage />,
        errorElement: <DashboardPageError />,
      },
      {
        path: links.attachments,
        element: <UserUtleggOverview />,
        errorElement: <DashboardPageError />,
      },
      {
        path: getParamLink("new_attachment"),
        element: <NewInternalUtlegg />,
        errorElement: <DashboardPageError />,
      },
      {
        path: getParamLink("detail_attachment"),
        element: <UtleggDetail />,
        errorElement: <DashboardPageError />,
      },
      {
        path: "/dashboard/admin",
        element: <AdminPanel />,
        errorElement: <DashboardPageError />,
        children: [
          {
            path: links.employees,
            element: <EmployeesTable />,
            errorElement: <DashboardPageError />,
          },
          {
            path: links.invites,
            element: <InvitationsTable />,
            errorElement: <DashboardPageError />,
          },
        ],
      },
      {
        path: links.adminAttachment,
        element: <AttachmentsPage />,
        errorElement: <DashboardPageError />,
        children: [
          {
            path: links.adminAttachment,
            element: <InternalAttachments />,
            errorElement: <DashboardPageError />,
          },
        ],
      },
      {
        path: getParamLink("edit_employee"),
        element: <EditEmployee />,
        errorElement: <DashboardPageError />,
      },
      {
        path: links.adminHours,
        element: <RegisteredHoursOverview />,
        errorElement: <DashboardPageError />,
      },
      {
        path: getParamLink("approve_hours"),
        element: <ApproveHours />,
        errorElement: <DashboardPageError />,
      },
      {
        path: getParamLink("timesheet_log"),
        element: <TimesheetLog />,
        errorElement: <DashboardPageError />,
      },
      {
        path: links.companySettings,
        element: <CompanySettings />,
        errorElement: <DashboardPageError />,
      },
    ],
  },
]);
