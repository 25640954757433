import { z } from "zod";
import { mod11SecurityDigitValid } from "../mod11.js";

export const UpdateOrganizationProfileSchema = z.object({
  name: z.string(),
  organization_id: z
    .string()
    .refine((v) => mod11SecurityDigitValid(v, "ORGANIZATION_ID"), {
      message: "Organisasjonsnummeret er ikke gyldig!",
    }),
  street_address: z.string(),
  postal_place: z.string(),
  postal_code: z.string(),
});

export type UpdateOrganizationProfile = z.infer<
  typeof UpdateOrganizationProfileSchema
>;

export const UpdateOrganizationAttachmentSettings = z.object({
  send_attachments: z.boolean(),
  attachment_reciever_email: z.string().email(),
});

export type UpdateOrganizationAttachment = z.infer<
  typeof UpdateOrganizationAttachmentSettings
>;

export const UpdateUserRoleSchema = z.object({
  user_id: z.string().uuid(),
  new_role: z.string(),
});

export type UpdateUserRole = z.infer<typeof UpdateUserRoleSchema>;

export const UpdateSalarySchema = z.object({
  hourly_rate: z
    .string()
    .refine(
      (v) => !Number.isNaN(Number.parseInt(v)) && Number.parseInt(v) > 0,
      {
        message: "Timelønn må være et positivt tall",
      },
    ),
  valid_from: z.date(),
  user_id: z.string().uuid(),
});

export type UpdateSalary = z.infer<typeof UpdateSalarySchema>;
