import { HStack, VStack, Text, IconButton } from "@chakra-ui/react";
import { AvatarContainer } from "components";
import { GrDocumentDownload } from "react-icons/gr";

type DetailBannerProps = {
  organizationName: string;
  organizationAvatarUrl?: string;
  attachmentId: string;
  attachmentTitle: string;
};

export function DetailBanner(props: DetailBannerProps) {
  return (
    <HStack w={"100%"} justifyContent={"space-between"}>
      <HStack>
        <AvatarContainer
          src={props.organizationAvatarUrl}
          fallbackName={props.organizationName}
          size="md"
        />
        <VStack>
          <Text>Utlegg #{props.attachmentId}</Text>
          <Text>{props.attachmentTitle}</Text>
        </VStack>
      </HStack>
      <HStack>
        <IconButton
          aria-label="Download attachment"
          icon={<GrDocumentDownload />}
        />
      </HStack>
    </HStack>
  );
}
