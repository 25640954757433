import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { SidebarButton } from "../components/SidebarButton";
import { SettingsIcon } from "@chakra-ui/icons";
import {
  FaBusinessTime,
  FaPlaneArrival,
  FaUserFriends,
  FaUserPlus,
} from "react-icons/fa";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { SelectTeam } from "features/team";
import { JoinCompany } from "features/user";
import { links } from "utils";
import { User } from "@supabase/supabase-js";
import { supabase } from "lib";
import useSession from "hooks/useSession";
import { RouterOutputs, trpc } from "utils/trpc";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";

interface NavbarContentProps {
  userProfile: NonNullable<RouterOutputs["userRouter"]["getUserProfile"]>;
  organization: RouterOutputs["organizationRouter"]["getOrganization"];
  userData: User;
}

export const Navbar = () => {
  const { session } = useSession();
  const { data: userProfile } = trpc.userRouter.getUserProfile.useQuery();
  const { selectedTeam } = useSelectedTeamv2();
  const { data: organization } =
    trpc.organizationRouter.getOrganization.useQuery(
      {
        organization_id: selectedTeam ?? "",
      },
      { enabled: !!selectedTeam },
    );

  if (session && userProfile && organization) {
    return (
      <NavbarContent
        userData={session.user}
        userProfile={userProfile}
        organization={organization}
      />
    );
  } else {
    return <LoadingNavbarContent />;
  }
};

const NavbarContent: React.FC<NavbarContentProps> = ({
  userData,
  userProfile,
  organization,
}) => {
  const navigate = useNavigate();
  const handleSignOut = () => {
    supabase.auth.signOut();
    navigate(links.login);
  };

  return (
    <Flex as="section" minH="100vh" zIndex={300}>
      <Stack
        position={"fixed"}
        flex="1"
        h={"100vh"}
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        bg="bg.accent.default"
        color="fg.accent.default"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="8">
          <SelectTeam />
          <VStack
            spacing="1"
            divider={<StackDivider borderColor="bg.accent.subtle" />}
          >
            <Box>
              <Text textStyle="sm" fontWeight="medium">
                Personlig
              </Text>
              <SidebarButton
                as={ReactRouterLink}
                to={links.timesheet}
                leftIcon={<FaBusinessTime />}
              >
                Mine timeføringer
              </SidebarButton>
              <SidebarButton
                as={ReactRouterLink}
                to={links.attachments}
                leftIcon={<FaPlaneArrival />}
              >
                Mine utlegg
              </SidebarButton>
            </Box>
          </VStack>
        </Stack>
        <Stack
          spacing="4"
          divider={<StackDivider borderColor="bg.accent.subtle" />}
        >
          <Stack>
            {organization.role === 1 || organization.role === 0 ? (
              <Box>
                <Text textStyle="sm" fontWeight="medium">
                  Bedrift
                </Text>
                <SidebarButton
                  as={ReactRouterLink}
                  to={links.employees}
                  leftIcon={<FaUserFriends />}
                >
                  Ansatte
                </SidebarButton>
                <SidebarButton
                  as={ReactRouterLink}
                  to={links.adminHours}
                  leftIcon={<FaBusinessTime />}
                >
                  Oversikt timeføringer
                </SidebarButton>
                <SidebarButton
                  as={ReactRouterLink}
                  to={links.adminAttachment}
                  leftIcon={<FaPlaneArrival />}
                >
                  Oversikt utlegg
                </SidebarButton>
                <SidebarButton
                  as={ReactRouterLink}
                  to={links.companySettings}
                  leftIcon={<SettingsIcon />}
                >
                  Instillinger bedrift
                </SidebarButton>
              </Box>
            ) : (
              <></>
            )}
          </Stack>
          <Stack spacing="1">
            <JoinCompany
              openModalComponent={
                <SidebarButton leftIcon={<FaUserPlus />}>
                  Bli med i bedrift
                </SidebarButton>
              }
            />

            <SidebarButton
              as={ReactRouterLink}
              to={links.profile}
              leftIcon={<FiSettings />}
            >
              Instillinger
            </SidebarButton>
            {userData.app_metadata.super_admin && (
              <SidebarButton
                as={ReactRouterLink}
                to={"/super_admin"}
                leftIcon={<FiSettings />}
              >
                super_admin
              </SidebarButton>
            )}
          </Stack>
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <Avatar
                boxSize="10"
                src={
                  userProfile?.avatar_url !== null && userProfile !== null
                    ? userProfile.avatar_url
                    : ""
                }
                name={
                  userProfile !== null && userProfile.first_name !== null
                    ? `${userProfile.first_name} ${userProfile.last_name}`
                    : ""
                }
              />
              <Box>
                <Text textStyle="sm" fontWeight="medium">
                  {userProfile !== null
                    ? `${userProfile.first_name} ${userProfile.last_name}`
                    : "Laster..."}
                </Text>
                <Text textStyle="sm" color="fg.accent.muted">
                  {userProfile !== null ? userProfile.email : "Laster..."}
                </Text>
              </Box>
            </HStack>
            <IconButton
              variant="tertiary.accent"
              icon={<FiLogOut />}
              aria-label="Logg ut"
              onClick={() => handleSignOut()}
            />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
};

const LoadingNavbarContent = () => {
  return (
    <Flex as="section" minH="100vh">
      <Stack
        position={"fixed"}
        flex="1"
        h={"100vh"}
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        bg="bg.accent.default"
        color="fg.accent.default"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="8">
          <SelectTeam />
          <Stack
            spacing="1"
            divider={<StackDivider borderColor="bg.accent.subtle" />}
          >
            <Box>
              <Text textStyle="sm" fontWeight="medium">
                Personlig
              </Text>
              <SidebarButton
                as={ReactRouterLink}
                to={links.timesheet}
                leftIcon={<FaBusinessTime />}
              >
                Mine timeføringer
              </SidebarButton>
              <SidebarButton
                as={ReactRouterLink}
                to={links.attachments}
                leftIcon={<FaPlaneArrival />}
              >
                Mine utlegg
              </SidebarButton>
            </Box>
          </Stack>
        </Stack>

        <Stack
          spacing="4"
          divider={<StackDivider borderColor="bg.accent.subtle" />}
        >
          <Box />
          <Stack spacing="1">
            <JoinCompany
              openModalComponent={
                <SidebarButton leftIcon={<FaUserPlus />}>
                  Bli med i bedrift
                </SidebarButton>
              }
            />
            <SidebarButton
              as={ReactRouterLink}
              to={links.profile}
              leftIcon={<FiSettings />}
            >
              Instillinger
            </SidebarButton>
          </Stack>
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <Avatar boxSize="10" src={""} name={"Loading"} />
              <Box>
                <Skeleton>
                  <Text textStyle="sm" fontWeight="medium">
                    Loading some name
                  </Text>
                </Skeleton>
                <Skeleton>
                  <Text textStyle="sm" color="fg.accent.muted">
                    Loading email
                  </Text>
                </Skeleton>
              </Box>
            </HStack>
            <IconButton
              variant="tertiary.accent"
              icon={<FiLogOut />}
              aria-label="Logg ut"
            />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
};
