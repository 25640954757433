import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Avatar,
  VStack,
  Text,
  TableContainer,
  Select,
  IconButton,
} from "@chakra-ui/react";
import { FiEdit2, FiSearch } from "react-icons/fi";
import { getParamHref, roleMap } from "utils";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { StandardTable, PagiationButtons, MidLoadingSpinner } from "components";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouterOutputs, trpc } from "utils/trpc";

export const EmployeesTable = () => {
  const { data, isLoading, isError } = trpc.adminRouter.getEmployees.useQuery();
  if (isLoading) {
    return <MidLoadingSpinner />;
  }

  if (isError) {
    return <p>Noe gikk galt</p>;
  }

  if (data) {
    return <Table employees={data} />;
  }

  return null;
};

interface MemberTableProps {
  employees: RouterOutputs["adminRouter"]["getEmployees"];
}

export const Table: React.FC<MemberTableProps> = ({ employees }) => {
  const columnHelper =
    createColumnHelper<MemberTableProps["employees"][number]>();
  const [field, setField] = useState("name_and_image");
  const [searchValue, setSearchValue] = useState("");
  const [columnFilters, setColumnFilters] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const defaultColums = [
    columnHelper.accessor("first_name", {
      id: "fornavn",
      header: "Fornavn",
      cell: (props) => {
        return (
          <HStack spacing="3">
            <Avatar
              name={props.row.original.first_name ?? ""}
              src={props.row.original.avatar_url}
              boxSize="10"
            />
            <Box>
              <Text fontWeight="medium">{props.row.original.first_name}</Text>
            </Box>
          </HStack>
        );
      },
    }),
    columnHelper.accessor("last_name", {
      header: "Etternavn",
    }),
    columnHelper.accessor("email", {
      header: "Epost",
    }),
    columnHelper.accessor("role", {
      header: "Rolle",
      cell: (props) => roleMap.get(props.row.original.role),
      enableColumnFilter: false,
    }),
    columnHelper.accessor("salary.hourlypay", {
      header: "Timelønn",
      cell: (props) => props.row.original.salary?.hourlypay ?? "",
      enableColumnFilter: false,
    }),
    columnHelper.accessor("accountnumber", {
      header: "Kontonummer",
    }),
    columnHelper.display({
      id: "actionButtons",
      header: "Handling",
      cell: (props) => (
        <IconButton
          icon={<FiEdit2 />}
          as={ReactRouterLink}
          to={getParamHref("edit_employee", props.row.original.userid)}
          variant="tertiary"
          aria-label="Edit member"
        />
      ),
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data: employees,
    columns: defaultColums,
    enableFilters: true,
    enableColumnFilters: true,
    state: {
      columnFilters,
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 6,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const filteringColumn = useMemo(() => {
    return table.getAllColumns().filter((col) => col.id === field)[0];
  }, [field, table]);

  useEffect(() => {
    if (filteringColumn) {
      filteringColumn.setFilterValue(searchValue);
    }
  }, [filteringColumn, searchValue]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setColumnFilters([]);
    setSearchValue("");
    setField(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <VStack gap={3}>
      <TableContainer w={"100%"}>
        <StandardTable
          table={table}
          emptyTableMessage={"Ingen ansatte funnet"}
        />
      </TableContainer>
      <Box px={{ base: "4", md: "6" }} pb="5" w={"100%"}>
        <VStack w={"100%"} alignItems={"end"}>
          <HStack>
            <Select onChange={handleSelectChange}>
              {table.getAllLeafColumns().map((column, index) => {
                if (column.getCanFilter()) {
                  return (
                    <option value={column.id} key={index}>
                      {String(column.columnDef.header)}
                    </option>
                  );
                }
                return null;
              })}
            </Select>
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="fg.muted" boxSize="5" />
              </InputLeftElement>
              <Input onChange={handleInputChange} placeholder="Søk" />
            </InputGroup>
          </HStack>
          <PagiationButtons table={table} />
        </VStack>
      </Box>
    </VStack>
  );
};
