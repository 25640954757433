import { links } from "@timetable/server/src/utils/Links";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trpc } from "utils/trpc";

export function useDashboardAuthProvider() {
  const navigate = useNavigate();
  const { data: userProfile, isLoading } =
    trpc.userRouter.getUserProfile.useQuery();

  useEffect(() => {
    if (userProfile) {
      if (!userProfile.onboarded) {
        const params = new URLSearchParams({ next: links.selectedTeam });
        navigate(links.onboarding_user_profile + `?${params.toString()}`);
        return;
      }
    }

    if (!userProfile && !isLoading) {
      throw new Error("No user profile!");
    }
  });
}
