import { useInfiniteQuery } from "@tanstack/react-query";
import { Enheter, Pages } from "types/brreg";

function getNextPageNumber(page: Pages | undefined) {
  if (!page) return undefined;
  if (page.number < page.totalPages) {
    return page.number + 1;
  } else {
    return undefined;
  }
}

export function useEnheterQuery(search: string) {
  return useInfiniteQuery({
    queryKey: ["enheter", search],
    queryFn: ({ pageParam }) => getResults(pageParam, search),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPageNumber(lastPage?.page),
  });
}

async function getResults(pageParam: number, search: string) {
  if (search === "" || !search) return;
  const params = new URLSearchParams({
    navn: search,
    size: "5",
    page: String(pageParam),
  });
  const res = await fetch(
    "https://data.brreg.no/enhetsregisteret/api/enheter?" + params.toString(),
  );
  const data = (await res.json()) as {
    _embedded: { enheter: Enheter[] };
    page: Pages;
  };
  return data;
}
