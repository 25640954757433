import {
  Center,
  IconButton,
  Spinner,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { MidLoadingSpinner } from "components";
import { sendMonthForApproval } from "../components/shared";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { MobileMonth } from "../components/MobileMonth";
import { trpc } from "utils/trpc";
import { links } from "@timetable/server/src/utils/Links";

export function MyHoursMobile() {
  const { data, isLoading, fetchNextPage, hasNextPage } =
    trpc.timesheetRouter.getMobileMonth.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        initialCursor: 1,
      },
    );
  const toast = useToast();
  const navigate = useNavigate();
  const utils = trpc.useUtils();

  if (isLoading) {
    return <MidLoadingSpinner />;
  }

  if (!data) {
    toast({
      title: "Kunne ikke hente data",
      status: "warning",
    });
    return null;
  }

  function sendMonth(
    year: number,
    month: number,
    company_id: string,
    currentCursor: number | undefined,
  ) {
    sendMonthForApproval(year, month, company_id).then((res) => {
      switch (res) {
        case 200:
          toast({
            title: "Timeliste sendt!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          if (currentCursor) {
            utils.timesheetRouter.getMobileMonth.refetch({
              cursor: currentCursor,
            });
          }
          // Refetch the month that was sent!

          break;
        case 500:
          toast({
            title: "Kunne ikke sende timeliste!",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          break;
        default:
          toast({
            title: "En ukjent feil!",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          break;
      }
    });
  }

  return (
    <>
      <IconButton
        position="fixed"
        bottom="4"
        right="4"
        zIndex="9999"
        isRound={true}
        size={"2xl"}
        variant="solid"
        aria-label="Done"
        fontSize="20px"
        icon={<FiPlus />}
        onClick={() => navigate(links.timesheetNew)}
      />

      <InfiniteScroll
        hasMore={hasNextPage}
        next={fetchNextPage}
        loader={
          <Center py={5}>
            <Spinner></Spinner>
          </Center>
        }
        dataLength={data.pages.length}
        endMessage={
          <Center py={10}>
            <Text>Ingen flere timeføringer å vise!</Text>
          </Center>
        }
      >
        <VStack mx={3}>
          {data.pages.map((page) => {
            return page.data.map((month) => (
              <MobileMonth
                month={month}
                sendMonth={sendMonth}
                cursor={page.nextCursor}
                key={month.id}
              />
            ));
          })}
        </VStack>
      </InfiniteScroll>
    </>
  );
}
