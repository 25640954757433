import {
  Avatar,
  Button,
  useDisclosure,
  Box,
  HStack,
  Icon,
  Text,
  Collapse,
  Stack,
} from "@chakra-ui/react";
import { AvalibleTeam } from "types";
import { FiChevronDown } from "react-icons/fi";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { FaBuilding } from "react-icons/fa";
import { useAvalibleTeamsForUserQuery } from "lib/queries";
import { trpc } from "utils/trpc";
import { links } from "@timetable/server/src/utils/Links";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";

export const SelectTeam = () => {
  const { data: avalibleTeams, isLoading: avalibleTeamsLoading } =
    useAvalibleTeamsForUserQuery();

  if (avalibleTeams && avalibleTeams?.length > 0 && !avalibleTeamsLoading) {
    return <TeamCollapse avalibleTeams={avalibleTeams} />;
  }
};

interface TeamCollapseProps {
  avalibleTeams: AvalibleTeam[];
}

export const TeamCollapse: React.FC<TeamCollapseProps> = ({
  avalibleTeams,
}) => {
  const { selectedTeam, setSelectedTeam } = useSelectedTeamv2();
  const navigate = useNavigate();
  const { data: organization } =
    trpc.organizationRouter.getOrganization.useQuery(
      {
        organization_id: selectedTeam ?? "",
      },
      { enabled: !!selectedTeam },
    );
  const { isOpen, onToggle } = useDisclosure();
  const utils = trpc.useUtils();
  return (
    <Box>
      <Button
        variant="tertiary.accent"
        onClick={onToggle}
        justifyContent="space-between"
        width="full"
        color={"inherit"}
      >
        <HStack spacing="3">
          {selectedTeam && organization ? (
            <>
              <Avatar
                src={organization.avatar_url ?? ""}
                name={organization.name}
                size={"sm"}
              />
              <Text as="span">{organization.name}</Text>
            </>
          ) : (
            <Text as="span">Velg bedrift</Text>
          )}
        </HStack>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="8" py="1">
          {avalibleTeams.map((team) => {
            if (team.company_id !== organization?.orgnumber) {
              return (
                <Button
                  variant="tertiary.accent"
                  key={team.company_id}
                  justifyContent="flex-start"
                  color={"inherit"}
                  width="full"
                  onClick={() => {
                    setSelectedTeam(team.company_id);
                    navigate(links.timesheet);
                    onToggle();
                    utils.invalidate();
                  }}
                >
                  <HStack>
                    <Avatar
                      src={team.avatar_url}
                      name={team.name}
                      size={"sm"}
                    />
                    <Text>{team.name}</Text>
                  </HStack>
                </Button>
              );
            }
            return null;
          })}

          <Button
            as={ReactRouterLink}
            to={links.admin_onboarding_start}
            variant="tertiary.accent"
            justifyContent="flex-start"
            color={"inherit"}
            width="full"
            leftIcon={<FaBuilding />}
          >
            <Text>Opprett ny bedrift</Text>
          </Button>
        </Stack>
      </Collapse>
    </Box>
  );
};

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};
