import { Tag, TagLabel } from "@chakra-ui/react";
import { Database } from "@timetable/server/src/types/supabase";

export function getStatusElements(
  status: Database["public"]["Enums"]["attachment_status"],
) {
  switch (status) {
    case "SENT":
      return (
        <Tag colorScheme="orange">
          <TagLabel>Sendt</TagLabel>
        </Tag>
      );
    case "DRAFT":
      return (
        <Tag colorScheme="gray">
          <TagLabel>Utkast</TagLabel>
        </Tag>
      );
  }
}
