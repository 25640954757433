import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Box,
  Flex,
  VStack,
  Text,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MonthTimeData } from "types";
import { formatter } from "lib";
import { AvatarContainer } from "components";
import { HoursTable } from "./HoursTable";
import { formatHours } from "utils/formatHours";
import { getMonthStatusIcons } from "features/timesheet/components/shared";
import { RouterOutputs } from "utils/trpc";

interface AccordionItemComponentProps {
  employee: RouterOutputs["adminRouter"]["getEmployees"][number];
  year: number;
  month: number;
  timeData: MonthTimeData;
}

export const AccordionItemComponent: React.FC<AccordionItemComponentProps> = ({
  employee,
  year,
  month,
  timeData,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const getTotalHoursForEmployee = (): number => {
    let sum: number = 0;
    for (let i = 0; i < timeData.entries.length; i++) {
      sum += timeData.entries[i].totalhours;
    }
    return Math.round(sum * 100) / 100;
  };

  const getTotalSalaryForEmployeeInPeriod = (): number => {
    let sum: number = 0;
    for (let i = 0; i < timeData.entries.length; i++) {
      sum += timeData.entries[i].pay;
    }
    return sum;
  };

  return (
    <AccordionItem>
      <AccordionButton flexWrap={"wrap"}>
        <HStack width={"100%"} justifyContent={"space-between"}>
          <HStack justifyContent={"space-between"} width={"100%"}>
            <HStack>
              <Box>
                <AvatarContainer
                  src={employee.avatar_url}
                  size={"md"}
                  fallbackName={employee.first_name ?? ""}
                />
              </Box>

              <Box textAlign="left" paddingLeft={"3"}>
                <Text fontWeight={"bold"} fontSize={"medium"} p={0}>
                  {employee.first_name} {employee.last_name}
                </Text>
                <Text fontSize={"small"} p={0}>
                  {employee.email}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Flex direction={"row"} justifyContent={"flex-start"} px={5}>
                <Box as="span" textAlign="left">
                  <Text size={"10"} mx={4}>
                    Timer
                  </Text>
                  <Text mx={4}>{formatHours(getTotalHoursForEmployee())}</Text>
                </Box>
                {isMobile ? null : (
                  <>
                    <Box as="span" textAlign="left">
                      <Text size={"10"} mx={4}>
                        Brutto
                      </Text>
                      <Text mx={4}>
                        {formatter.format(getTotalSalaryForEmployeeInPeriod())}
                      </Text>
                    </Box>
                    <VStack justifyContent={"center"}>
                      {getMonthStatusIcons(
                        timeData.admin_approved_month,
                        timeData.locked,
                        true,
                      )}
                    </VStack>
                  </>
                )}
              </Flex>
            </HStack>
          </HStack>
          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <HoursTable timeData={timeData} />
    </AccordionItem>
  );
};
