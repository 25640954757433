import {
  FormControl,
  FormLabel,
  Tag,
  Avatar,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { sortNameList } from "utils";
import { RouterOutputs } from "utils/trpc";

type Employees = RouterOutputs["adminRouter"]["getEmployees"];

interface SelectTagEmployeesProps {
  selectedEmployees: Employees;
  allEmployees: Employees;
  setSelectedEmployees: Dispatch<SetStateAction<Employees>>;
  setAllEmployees: Dispatch<SetStateAction<Employees>>;
}

export const SelectTagEmployees: React.FC<SelectTagEmployeesProps> = ({
  selectedEmployees,
  allEmployees,
  setSelectedEmployees,
  setAllEmployees,
}) => {
  const handleEmployeeSelect = (employee: Employees[number]) => {
    setSelectedEmployees(sortNameList([...selectedEmployees, employee]));
    let c = allEmployees;
    let indexOfEmployee = 0;
    for (let i = 0; i < c.length; i++) {
      if (c[i].userid === employee.userid) {
        indexOfEmployee = i;
      }
    }
    c.splice(indexOfEmployee, 1);
    setAllEmployees(sortNameList(c));
  };

  const handleEmployeeUnselect = (employee: Employees[number]) => {
    setAllEmployees(sortNameList([...allEmployees, employee]));
    let c = selectedEmployees;
    let indexOfEmployee = 0;
    for (let i = 0; i < c.length; i++) {
      if (c[i].userid === employee.userid) {
        indexOfEmployee = i;
      }
    }
    c.splice(indexOfEmployee, 1);
    setSelectedEmployees(sortNameList(c));
  };
  return (
    <>
      {selectedEmployees.length === 0 ? (
        <></>
      ) : (
        <FormControl>
          <FormLabel>Valgt</FormLabel>
          {selectedEmployees.map((employee) => {
            return (
              <Tag
                key={`subset${employee.userid}`}
                margin={"1"}
                size="lg"
                colorScheme="green"
                borderRadius="full"
              >
                <Avatar ml={-1} mr={2} size={"xs"} src={employee.avatar_url} />
                <TagLabel>
                  {employee?.first_name?.length! > 7
                    ? `${employee.first_name!.substring(0, 7)}...`
                    : employee.first_name}
                </TagLabel>
                <TagCloseButton
                  onClick={() => handleEmployeeUnselect(employee)}
                />
              </Tag>
            );
          })}
        </FormControl>
      )}
      {allEmployees.length === 0 ? (
        <></>
      ) : (
        <FormControl>
          <FormLabel>Ikke valgt</FormLabel>
          {allEmployees.map((employee) => {
            return (
              <Tag
                key={`all${employee.userid}`}
                size="lg"
                margin={"1"}
                colorScheme="blue"
                borderRadius="full"
                cursor={"pointer"}
                onClick={() => handleEmployeeSelect(employee)}
              >
                <Avatar ml={-1} mr={2} size={"xs"} src={employee.avatar_url} />
                <TagLabel>
                  {employee?.first_name?.length! > 7
                    ? `${employee.first_name!.substring(0, 7)}...`
                    : employee.first_name}
                </TagLabel>
              </Tag>
            );
          })}
        </FormControl>
      )}
    </>
  );
};
