// Sentry initialization should be imported first!
import "./instrument";

import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { theme } from "./theme";
import { DateProvider } from "context/DateProvider";
import { SelectedCompanyProvider } from "context/SelectedCompanyProvider";
import { SessionProvider } from "context/SessionContext";
import { TrpcProvider } from "TrpcProvider";
import { router } from "router";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <SessionProvider>
        <SelectedCompanyProvider>
          <TrpcProvider>
            <DateProvider>
              <RouterProvider router={router} />
            </DateProvider>
          </TrpcProvider>
        </SelectedCompanyProvider>
      </SessionProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
