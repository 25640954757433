import {
  Box,
  HStack,
  Heading,
  IconButton,
  VStack,
  Text,
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import WeekDivider from "./WeekDivider";
import SingleEntryCard from "./SingleEntryCard";
import { getMonthStatusIcons, getTotals, sortIntoWeeks } from "./shared";
import { RouterOutputs } from "utils/trpc";

export function MobileMonth(props: {
  month: RouterOutputs["timesheetRouter"]["getMobileMonth"]["data"][number];
  sendMonth: (
    year: number,
    month: number,
    company_id: string,
    currentCursor: number | undefined,
  ) => void;
  cursor: number | undefined;
}) {
  const { month, sendMonth } = props;

  if (month.entries.length === 0) return null;

  const { totalHours } = getTotals(month.entries);

  return (
    <Box key={month.id} width={"100%"}>
      <HStack
        justifyContent={"space-between"}
        width={"100%"}
        height={"20"}
        bg={"bg.gray"}
      >
        <VStack alignItems={"flex-start"}>
          <HStack>
            <Heading size={"xs"}>
              {formatMonth(new Date(month.year, month.month, 1))}
            </Heading>
            {getMonthStatusIcons(
              month.admin_approved_month,
              month.locked,
              false,
            )}
          </HStack>
          <HStack>
            <Text>{totalHours}</Text>
          </HStack>
        </VStack>
        {!month.locked && (
          <IconButton
            icon={<FiSend />}
            aria-label={"Send month"}
            variant={"ghost"}
            onClick={() =>
              sendMonth(month.year, month.month, month.company_id, props.cursor)
            }
          />
        )}
      </HStack>
      <VStack gap="4">
        {sortIntoWeeks(month.entries).map((week) => {
          const totals = getTotals(week.entries);
          return (
            <Box key={week.weekNumber} width={"100%"}>
              <WeekDivider
                weekNumber={week.weekNumber}
                weekTotal={totals.totalHours}
              />
              <VStack gap={"2"}>
                {week.entries.map((entry) => (
                  <SingleEntryCard
                    entry={entry}
                    key={entry.entryid}
                    month_status={month.locked}
                  />
                ))}
              </VStack>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
}

function formatMonth(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };

  const repr = date.toLocaleDateString("no-NO", options);
  return repr[0].toUpperCase() + repr.slice(1, repr.length);
}
