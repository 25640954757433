import { ModuleLayout } from "layout";
import { TableWrapper } from "../components/company/TableWrapper";

export const AdminPanel = () => {
  return (
    <ModuleLayout pageTitle={"Ansattoversikt"} minWidth="container.lg">
      <TableWrapper />
    </ModuleLayout>
  );
};
