import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
  useDisclosure,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormRegister, TimeRegisterData } from "./formRegister";
import { FaRegCalendarPlus } from "react-icons/fa";
import { SubmitHandler, useForm } from "react-hook-form";
import { authAxios } from "lib/axios";
import { trpc } from "utils/trpc";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";

export const NewHourEntry = () => {
  const { data: user } = trpc.userRouter.getUserProfile.useQuery();
  const { selectedTeam } = useSelectedTeamv2();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const utils = trpc.useUtils();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<TimeRegisterData>({
    defaultValues: {
      date: new Date().toISOString().substring(0, 10),
    },
  });

  const onSumbit: SubmitHandler<TimeRegisterData> = async (data) => {
    if (!user) return;
    const params = {
      ...data,
      company_id: selectedTeam,
    };

    authAxios
      .post("/timesheet/user", params)
      .then((res) => {
        if (res.status === 201) {
          toast({
            title: "Timeføring opprettet!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          utils.timesheetRouter.getDesktopMonth.invalidate();
          utils.timesheetRouter.getMobileMonth.invalidate();
          onClose();
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toast({
            title: "Timeliste er låst!",
            description:
              "Timelisten for denne måneden er låst. Kanskje du mente å føre på en annen måned?",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Noe gikk galt!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <>
      <Button size={"sm"} leftIcon={<FaRegCalendarPlus />} onClick={onOpen}>
        Ny
      </Button>

      <Modal
        size={["sm", "md", "xl"]}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ny timeføring</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormRegister
              onSumbit={onSumbit}
              register={register}
              handleSubmit={handleSubmit}
              control={control}
              watch={watch}
            />
            <ModalFooter>
              <Button
                isLoading={isSubmitting}
                type="submit"
                form="registerHoursForm"
              >
                Registrer
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
