import React, { useEffect } from "react";
import { NoEntriesView } from "components";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Box,
  HStack,
  Text,
  useBreakpointValue,
  Stack,
  Tag,
  IconButton,
} from "@chakra-ui/react";
import { getDateString, getSingleTimeString } from "utils";
import { formatter } from "lib";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { EditHourEntry } from "./EditHourEntry";
import { NewHourEntry } from "./NewHourEntry";
import { PagiationButtons } from "components";
import { getTotals, useDeleteTimesheetEntryMutation } from "./shared";
import { formatHours } from "utils/formatHours";
import { RouterOutputs } from "utils/trpc";
import { showConfirmDialogue } from "components/AlertDialogue";

export interface MyHoursTableProps {
  entries: NonNullable<
    RouterOutputs["timesheetRouter"]["getDesktopMonth"]
  >["entries"];
  status: boolean;
}

export const rowStatus = {
  0: <Tag>Ubehandlet</Tag>,
  1: <Tag colorScheme={"red"}>Ikke godkjent</Tag>,
  2: <Tag colorScheme={"green"}>Godkjent</Tag>,
};

export const rowStatusMap = new Map<number, JSX.Element>([
  [0, <Tag>Ubehandlet</Tag>],
  [1, <Tag colorScheme="red">Ikke godkjent</Tag>],
  [2, <Tag colorScheme="green">Godkjent</Tag>],
]);

export const MyHoursTable: React.FC<MyHoursTableProps> = ({
  entries,
  status,
}) => {
  const columnHelper =
    createColumnHelper<MyHoursTableProps["entries"][number]>();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const { mutate, isPending } = useDeleteTimesheetEntryMutation();

  function handleDeleteTimesheetEntry(entryid: string | number) {
    showConfirmDialogue({
      title: "Slett timeføring",
      description: "Er du sikker på at du vil slette timeføringen?",
      onAccept() {
        mutate(entryid);
      },
      isPending: isPending,
      variant: "delete",
    });
  }

  const columnBasedOnStatus = {
    false: (
      row: CellContext<MyHoursTableProps["entries"][number], unknown>,
    ) => (
      <HStack>
        <EditHourEntry entry={row.row.original} />
        <IconButton
          data-cy="delete_button"
          size={"sm"}
          colorScheme="red"
          onClick={() => handleDeleteTimesheetEntry(row.row.original.entryid)}
          icon={<DeleteIcon />}
          aria-label={"slett timeføring"}
        />
      </HStack>
    ),
    true: (row: CellContext<MyHoursTableProps["entries"][number], unknown>) =>
      rowStatusMap.get(row.row.original.admin_approved),
  };

  const columns = [
    columnHelper.accessor("fromdate", {
      header: () => "Dato",
      footer: () => <Text fontWeight={"bold"}>Totalt</Text>,
      cell: ({ getValue }) => getDateString(new Date(getValue())),
    }),
    columnHelper.accessor("fromdate", {
      id: "fromdate_time",
      header: () => "Fra",
      cell: ({ getValue }) => getSingleTimeString(new Date(getValue())),
    }),
    columnHelper.accessor("todate", {
      header: () => "Til",
      cell: ({ getValue }) => getSingleTimeString(new Date(getValue())),
    }),
    columnHelper.accessor("totalhours", {
      header: () => "Antall timer",
      cell: ({ getValue }) => formatHours(getValue() ?? 0),
      footer: () => (
        <Text fontWeight={"bold"}>{getTotals(entries).totalHours}</Text>
      ),
    }),
    ...(getTotals(entries).totalPay !== 0
      ? [
          columnHelper.accessor("hourly_rate", {
            header: () => <Text>Brutto timelønn</Text>,
            cell: ({ getValue, row }) =>
              formatter.format(
                (getValue() ?? 0) * (row.original.totalhours ?? 0),
              ),
            footer: () => (
              <Text fontWeight={"bold"}>
                {formatter.format(getTotals(entries).totalPay)}
              </Text>
            ),
          }),
        ]
      : []),
    columnHelper.display({
      id: "buttons",
      cell: (row) =>
        status ? columnBasedOnStatus.true(row) : columnBasedOnStatus.false(row),
    }),
  ];
  const table = useReactTable({
    data: entries,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    onSortingChange: setSorting,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    setSorting([{ id: "fromdate", desc: false }]);
  }, []);
  return (
    <>
      {entries.length > 0 ? (
        <>
          <Box p={0}>
            <Stack spacing="5">
              <Box overflowX="auto">
                <Table variant={"striped"} size={isMobile ? "sm" : "lg"}>
                  <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Th key={header.id}>
                            {header.isPlaceholder ? null : (
                              <HStack
                                justifyContent={"flex-start"}
                                cursor={
                                  header.column.getCanSort()
                                    ? "pointer"
                                    : "default"
                                }
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                <Box>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </Box>
                                {{
                                  asc: <FaSortUp />,
                                  desc: <FaSortDown />,
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </HStack>
                            )}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row) => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                      <Tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                          <Th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.footer,
                                  header.getContext(),
                                )}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Tfoot>
                </Table>
              </Box>
              <Box px={{ base: "4", md: "6" }} pb="5">
                <PagiationButtons table={table} />
              </Box>
            </Stack>
          </Box>
        </>
      ) : (
        <NoEntriesView
          message="Ingen timeføringer"
          actionFooter={<NewHourEntry />}
        />
      )}
    </>
  );
};
