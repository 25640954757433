import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useSetSelectedTeamMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["setSelectedTeamMutation"],
    mutationFn: async (organization_id: string): Promise<void> => {
      try {
        localStorage.setItem("selectedTeam", organization_id);
        queryClient.invalidateQueries({ queryKey: ["useSelectedTeamQuery"] });
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
  });
}
