import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatter, makeRequest } from "../../../lib";
import React, {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { MidLoadingSpinner, StandardTable } from "../../../components";
import { MonthTimeData, Timetableentry } from "../../../types";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Button,
  Checkbox,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { getDateString, getDoubleTimeString } from "../../../utils";
import { ApproveHeader } from "../components/timesheet/ApproveHeader";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ModuleLayout } from "layout";
import { useSelectedMonth } from "hooks/useSelectedMonth";
import { useAllTimeEntriesInOrganizationQuery } from "lib/queries";
import { useQueryClient } from "@tanstack/react-query";
import { RouterOutputs, trpc } from "utils/trpc";

interface ApproveModuleProps {
  monthData: MonthTimeData;
  employee: RouterOutputs["adminRouter"]["getEmployees"][number];
}

interface FormValues {
  selected_entries: number[];
}

export const ApproveHours = () => {
  const { user_id } = useParams();
  const [searchParams] = useSearchParams();
  const { data: timeentries } = useAllTimeEntriesInOrganizationQuery();
  const { data: employees } = trpc.adminRouter.getEmployees.useQuery();
  const { setMonth, setYear } = useSelectedMonth();

  useEffect(() => {
    const month = searchParams.get("month");
    const year = searchParams.get("year");
    if (month && year !== null) {
      setMonth(Number.parseInt(month));
      setYear(Number.parseInt(year));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (searchParams.get("month") === null || searchParams.get("year") === null)
    return <p>Noe galt med link</p>;

  if (timeentries && employees) {
    const userEntry = timeentries.find((entry) => entry.user_id === user_id);
    const employee = employees.find(
      (employee) => employee.userid === userEntry?.user_id,
    );
    if (userEntry === undefined || employee === undefined) return <p>LOL</p>;
    return (
      <ModuleLayout pageTitle={"Godkjenn timeliste"} minWidth={"container.lg"}>
        <ApproveModule monthData={userEntry} employee={employee} />
      </ModuleLayout>
    );
  } else {
    return <MidLoadingSpinner />;
  }
};

export const ApproveModule: React.FC<ApproveModuleProps> = ({
  monthData,
  employee,
}) => {
  const methods = useForm<FormValues>();
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedEntries, setSelectedEntries] = useState<
    Record<string, boolean>
  >({});
  const queryClient = useQueryClient();
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    let selected_ids = [];
    for (let index in selectedEntries) {
      selected_ids.push(
        Number.parseInt(
          String(monthData.entries[Number.parseInt(index)].entryid),
        ),
      );
    }

    const ops = {
      user_id: monthData.user_id,
      company_id: Number.parseInt(String(monthData.company_id)),
      month: monthData.month,
      year: monthData.year,
      approved_entries: selected_ids,
    };

    const res = await makeRequest(
      ops,
      null,
      "/admin/timesheet/approve",
      true,
      "POST",
    );
    switch (res.status) {
      case 200:
        toast({
          title: "Måned godkjent!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.refetchQueries({
          queryKey: ["allTimeEntriesInOrganization"],
        });
        navigate(-1);
        break;
      default:
        toast({
          title: "Sjekk din epost!",
          description:
            "Vi har sendt deg en lenke på epost for å tilbakestille ditt passord.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <ApproveHeader employee={employee} />
      <VStack gap={3} as={"form"} onSubmit={methods.handleSubmit(onSubmit)}>
        <ApproveHoursTable
          entries={monthData.entries}
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
        />
        <Button type="submit" isLoading={methods.formState.isSubmitting}>
          Godkjenn
        </Button>
      </VStack>
    </FormProvider>
  );
};

const ApproveHoursTable = (props: {
  entries: Timetableentry[];
  selectedEntries: Record<string, boolean>;
  setSelectedEntries: Dispatch<SetStateAction<Record<string, boolean>>>;
}) => {
  const columnHelper = createColumnHelper<Timetableentry>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const defaultColumns = [
    columnHelper.display({
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor("entryid", {
      header: "id",
    }),
    columnHelper.accessor("fromdate", {
      header: "Dato",
      cell: (props) => getDateString(new Date(props.row.original.fromdate)),
    }),
    columnHelper.accessor("totalhours", {
      header: "Antall timer",
      cell: (props) => String(props.row.original.totalhours).replace(".", ","),
    }),
    columnHelper.accessor("todate", {
      header: "Tidsrom",
      cell: (props) =>
        getDoubleTimeString(
          new Date(props.row.original.fromdate),
          new Date(props.row.original.todate),
        ),
    }),
    columnHelper.accessor("pay", {
      header: "Brutto",
      cell: (props) => formatter.format(props.row.original.pay),
    }),
    columnHelper.accessor("description", {
      header: "Beskrivelse",
      cell: (props) => {
        if (props.row.original.description.length > 10) {
          return (
            <Popover>
              <PopoverTrigger>
                <Button size={"xs"} variant={"ghost"}>
                  Vis beskrivelse
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader>Beskrivelse</PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Text>{props.row.original.description}</Text>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          );
        } else {
          return <p>{props.row.original.description}</p>;
        }
      },
    }),
  ];

  const table = useReactTable({
    data: props.entries,
    columns: defaultColumns,

    state: {
      sorting,
      rowSelection: props.selectedEntries,
    },
    enableRowSelection: true, //enable row selection for all rows
    onRowSelectionChange: props.setSelectedEntries,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return <StandardTable table={table} emptyTableMessage={"No entries found"} />;
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return (
    <>
      <Checkbox
        ref={ref}
        isChecked={rest.checked}
        isIndeterminate={!rest.checked && indeterminate}
        onChange={rest.onChange}
      />
    </>
  );
}
