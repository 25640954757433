import { Button, SimpleGrid } from "@chakra-ui/react";
import { CompanyCard } from "./CompanyCard";
import { CompanyInfo } from "./CompanyInfo";
import { UserRoleInCompany } from "./UserRoleInCompany";
import { useNavigate } from "react-router-dom";
import { MidLoadingSpinner, NoEntriesView } from "components";
import { TeamActionButtons } from "./TeamActionButtons";
import { useAvalibleTeamsForUserQuery } from "lib/queries";
import { links } from "@timetable/server/src/utils/Links";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";

export const InitialTeamSelectGridSelector = () => {
  const { data: avalibleTeams, isLoading } = useAvalibleTeamsForUserQuery();
  const { setSelectedTeam } = useSelectedTeamv2();
  const navigate = useNavigate();

  if (isLoading) return <MidLoadingSpinner />;

  if (avalibleTeams) {
    return (
      <SimpleGrid
        columns={{ base: 1, md: avalibleTeams.length === 0 ? 1 : 3 }}
        spacing="6"
      >
        {avalibleTeams.length === 0 ? (
          <NoEntriesView
            gridRow={2}
            message="Du er ikke medlem av noen bedrifter"
            actionFooter={<TeamActionButtons />}
          />
        ) : (
          avalibleTeams.map((team) => {
            return (
              <CompanyCard
                key={team.company_id}
                avatarProps={{ src: team.avatar_url, name: team.name }}
              >
                <CompanyInfo mt="3" name={team.name} />
                <UserRoleInCompany
                  role={team.role}
                  is_owner={team.role === 0}
                />
                <Button
                  rounded="full"
                  mt={3}
                  w={"80px"}
                  size="sm"
                  onClick={() => {
                    setSelectedTeam(team.company_id);
                    navigate(links.timesheet);
                  }}
                >
                  Bruk
                </Button>
              </CompanyCard>
            );
          })
        )}
      </SimpleGrid>
    );
  }
};
