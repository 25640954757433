export const links = {
  timesheet: "/dashboard/timesheet",
  timesheetNew: "/dashboard/timesheet/new",
  attachments: "/dashboard/attachments",
  profile: "/dashboard/profile",
  onboarding_user_profile: "/onboarding/userProfile",
  employees: "/dashboard/admin/employees",
  invites: "/dashboard/admin/employees/invites",
  adminHours: "/dashboard/admin/hours",
  adminAttachment: "/dashboard/admin/attachment",
  adminAttachmentExternal: "/dasboard/admin/attachment/external",
  previewDoc: "/dashboard/admin/attachment/preview",
  companySettings: "/dashboard/admin/settings",
  login: "/login",
  selectedTeam: "/selectteam",
  newAttachment: "/dashboard/attachments/new",
  admin_onboarding_start: "/onboarding/start",
  admin_onboarding_create_organization_auto:
    "/onboarding/create-organization/auto",
  admin_onboarding_create_organization_manual:
    "/onboarding/create-organization/manual",
  applyInvite: "/invite",
};

const paramLinks = {
  admin_onboarding_choose_plan: {
    href: (id: number | string) => `/onboarding/${id}/choose_plan`,
    path: "/onboarding/:id/choose_plan",
  },
  admin_onboarding_invite_employees: {
    href: (id: number | string) => `/onboarding/${id}/invite_employees`,
    path: "/onboarding/:id/invite_employees",
  },
  loginWithOtp: {
    href: (id: number | string) => `/otp/${id}`,
    path: "/otp/:email",
  },
  admin_onboarding_finish: {
    href: (id: number | string) => `/onboarding/${id}/finish`,
    path: "/onboarding/:id/finish",
  },
  edit_employee: {
    href: (id: number | string) => `/dashboard/admin/employees/${id}`,
    path: "/dashboard/admin/employees/:user_id",
  },
  approve_hours: {
    href: (id: number | string) => `/dashboard/admin/hours/${id}`,
    path: "/dashboard/admin/hours/approve/:user_id",
  },
  timesheet_log: {
    href: (id: number | string) => `/dashboard/admin/hours/log/${id}`,
    path: "/dashboard/admin/hours/log/:month_id",
  },
  timesheet_edit: {
    href: (id: number | string) => `/dashboard/timesheet/edit/${id}`,
    path: "/dashboard/timesheet/edit/:entryid",
  },
  new_attachment: {
    href: (id: number | string) => `/dashboard/attachment/new/${id}`,
    path: "/dashboard/attachment/new/:attachmentId",
  },
  detail_attachment: {
    href: (id: number | string) => `/dashboard/attachment/${id}`,
    path: "/dashboard/attachment/:attachmentId",
  },
};

type ParamKeys = keyof typeof paramLinks;

export function getParamLink(paramPage: ParamKeys) {
  return paramLinks[paramPage].path;
}

export function getParamHref(
  paramPage: ParamKeys,
  val: number | string | null | undefined,
) {
  if (!val) throw new Error("No val!");
  return paramLinks[paramPage].href(val);
}
