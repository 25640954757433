import {
  AccordionPanel,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MonthTimeData, Timetableentry } from "types";
import { StandardTable, PagiationButtons } from "components";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateString, getDoubleTimeString } from "utils";
import { useState } from "react";
import { formatter } from "../../../../lib";
import { Link as ReactRouterLink } from "react-router-dom";
import { authAxios } from "lib/axios";
import { formatHours } from "utils/formatHours";
import { useQueryClient } from "@tanstack/react-query";

interface HoursTableProps {
  timeData: MonthTimeData;
}
export const HoursTable: React.FC<HoursTableProps> = ({ timeData }) => {
  const columnHelper = createColumnHelper<Timetableentry>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const toast = useToast();
  const queryClient = useQueryClient();

  async function reopenMonth(
    company_id: number,
    user_id: string,
    month: number,
    year: number,
  ) {
    const answer = window.confirm(
      "Vil du åpne denne måneden igjen? Dette tilbakestiller alle tidligere godkjenninger.",
    );
    if (answer) {
      const options = {
        company_id: Number.parseInt(String(company_id)),
        user_id: user_id,
        month: month,
        year: year,
      };
      const res = await authAxios.patch("/admin/timesheet/reset", options);

      switch (res.status) {
        case 200:
          queryClient.refetchQueries({
            queryKey: ["allTimeEntriesInOrganization"],
          });
          toast({
            title: "Timeliste åpnet!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          return;
        case 403:
          toast({
            title: "Du kan ikke utføre denne operasjonen!",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          return;
        case 500:
          toast({
            title: "Noe gikk galt!",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
      }
    }
  }

  const defaultColumns = [
    columnHelper.accessor("fromdate", {
      header: "Dato",
      cell: (props) => getDateString(new Date(props.row.original.fromdate)),
    }),
    columnHelper.accessor("totalhours", {
      header: "Antall timer",
      cell: (props) => formatHours(props.row.original.totalhours),
    }),
    columnHelper.accessor("todate", {
      header: "Tidsrom",
      cell: (props) =>
        getDoubleTimeString(
          new Date(props.row.original.fromdate),
          new Date(props.row.original.todate),
        ),
    }),
    columnHelper.accessor("pay", {
      header: "Brutto",
      cell: (props) => formatter.format(props.row.original.pay),
    }),
    columnHelper.accessor("description", {
      header: "Beskrivelse",
      cell: (props) => {
        if (props.row.original.description.length > 10) {
          return (
            <Popover>
              <PopoverTrigger>
                <Button size={"xs"} variant={"ghost"}>
                  Vis beskrivelse
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader>Beskrivelse</PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Text>{props.row.original.description}</Text>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          );
        }
      },
    }),
  ];

  const table = useReactTable({
    data: timeData.entries,
    columns: defaultColumns,
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <AccordionPanel pb={4}>
      <HStack justifyContent={"space-between"}>
        <HStack>
          {timeData.locked &&
            (timeData.admin_approved_month === 0 ||
              timeData.admin_approved_month === 1) && (
              <Button
                as={ReactRouterLink}
                to={`/dashboard/admin/hours/approve/${timeData.user_id}?month=${timeData.month}&year=${timeData.year}`}
              >
                Godkjenning
              </Button>
            )}
          {timeData.locked && (
            <Button
              onClick={() =>
                reopenMonth(
                  timeData.company_id,
                  timeData.user_id,
                  timeData.month,
                  timeData.year,
                )
              }
            >
              Åpne timeliste
            </Button>
          )}
        </HStack>
        <Button as={ReactRouterLink} to={`/admin/hours/log/${timeData.id}`}>
          Endringslogg
        </Button>
      </HStack>
      <VStack>
        <StandardTable
          table={table}
          emptyTableMessage={"Ingen timeføringer funnet."}
        ></StandardTable>
        <PagiationButtons table={table} />
      </VStack>
    </AccordionPanel>
  );
};
