import { Box, Container, Stack } from "@chakra-ui/react";
import { Step } from "./Step";
import { useStep } from "./useStep";
import { getParamLink, links } from "utils";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";

export const StepStepper = () => {
  const location = useLocation();
  const { id } = useParams();

  const steps = useMemo(() => {
    return [
      {
        title: "Steg 1",
        description: "Organisasjon",
        href: [
          links.admin_onboarding_create_organization_auto,
          links.admin_onboarding_create_organization_manual,
        ],
      },
      {
        title: "Steg 2",
        description: "Betaling",
        href: [getParamLink("admin_onboarding_choose_plan")],
      },
      {
        title: "Steg 3",
        description: "Ansatte",
        href: [getParamLink("admin_onboarding_invite_employees")],
      },
      {
        title: "Steg 4",
        description: "Fullfør",
        href: [getParamLink("admin_onboarding_finish")],
      },
    ];
  }, []);

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: steps.findIndex((d) => d.href.includes(location.pathname)),
  });

  useEffect(() => {
    console.log(location.pathname.split("/").splice(-1)[0]);
    const currentStep = steps.findIndex(
      (step) =>
        step.href.findIndex((link) =>
          link.includes(location.pathname.split("/").splice(-1)[0]),
        ) !== -1,
    );
    console.log(currentStep);
    setStep(currentStep);
  }, [location, id, setStep, steps]);

  return (
    <Box width={"full"}>
      <Container py={{ base: "4", md: "8" }}>
        <Stack spacing="0" direction={{ base: "column", md: "row" }}>
          {steps.map((step, id) => (
            <Step
              key={id}
              cursor="pointer"
              onClick={() => setStep(id)}
              title={step.title}
              description={step.description}
              isActive={currentStep === id}
              isCompleted={currentStep > id}
              isFirstStep={id === 0}
              isLastStep={steps.length === id + 1}
            />
          ))}
        </Stack>
      </Container>
    </Box>
  );
};
