import { Box, Container, Heading, useBreakpointValue } from "@chakra-ui/react";

interface ModuleLayoutProps {
  pageTitle: String;
  minWidth?: "container.sm" | "container.md" | "container.lg" | "container.xl";
  children: React.ReactNode;
}

export const ModuleLayout: React.FC<ModuleLayoutProps> = ({
  pageTitle,
  children,
  minWidth,
}) => {
  const isMobile = useBreakpointValue(
    { base: true, xl: false },
    { ssr: false },
  );
  return (
    <Container mt={5}>
      <Heading size={isMobile ? "xs" : "md"}>{pageTitle}</Heading>
      <Box>{children}</Box>
    </Container>
  );
};
