import { links } from "@timetable/server/src/utils/Links";
import { useSetSelectedTeamMutation } from "lib/mutations";
import { useSelectedTeamQuery } from "lib/queries";
import { useLocation, useNavigate } from "react-router-dom";
import useSession from "./useSession";

/**
 * Hook for getting the currently selected team stored in localstorage. Will navigate to selectTeam if there is no selectedTeam present in local storage.
 */
export function useSelectedTeamv2() {
  const { data, isLoading } = useSelectedTeamQuery();
  const { mutate } = useSetSelectedTeamMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();

  if (!isLoading && !data && session.session) {
    // If we already are at selectTeam don't navigate.
    if (
      location.pathname !== links.selectedTeam &&
      !location.pathname.includes("onboarding")
    ) {
      console.log("JEG ER KALLT");
      navigate(links.selectedTeam);
    }
  }
  return { selectedTeam: data, setSelectedTeam: mutate, isLoading: isLoading };
}
