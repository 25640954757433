import { Button, useToast, Heading, VStack } from "@chakra-ui/react";
import { FormRegister, TimeRegisterData } from "../components/formRegister";
import { SubmitHandler, useForm } from "react-hook-form";
import { authAxios } from "lib/axios";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleTimeString } from "utils";
import { MidLoadingSpinner } from "components";
import { RouterOutputs, trpc } from "utils/trpc";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";

export const NewHourEntryPage = () => {
  return <NewHourEntry />;
};

export function EditHourEntryPage() {
  const { entryid } = useParams();
  const { data, isLoading } =
    trpc.timesheetRouter.getSingeTimesheetRow.useQuery({ entry_id: entryid });

  if (isLoading) {
    return <MidLoadingSpinner />;
  }

  if (data) {
    return <NewHourEntry data={data} />;
  }

  return null;
}

const NewHourEntry = (props: {
  data?: RouterOutputs["timesheetRouter"]["getSingeTimesheetRow"];
}) => {
  const navigate = useNavigate();
  const { data } = props;
  const { selectedTeam } = useSelectedTeamv2();
  const toast = useToast();
  const utils = trpc.useUtils();

  let defaultValues;
  if (data) {
    defaultValues = {
      date: new Date(data.fromdate).toISOString().substring(0, 10),
      startTime: getSingleTimeString(new Date(data.fromdate)),
      endTime: getSingleTimeString(new Date(data.todate)),
      description: data.description,
    };
  } else {
    defaultValues = {
      date: new Date().toISOString().substring(0, 10),
    };
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<TimeRegisterData>({
    defaultValues: defaultValues,
  });

  function createNewHourEntry(entry: TimeRegisterData) {
    const params = {
      ...entry,
      company_id: selectedTeam,
    };

    authAxios
      .post("/timesheet/user", params)
      .then((res) => {
        if (res.status === 201) {
          toast({
            title: "Timeføring opprettet!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate(-1);
          utils.timesheetRouter.getDesktopMonth.invalidate();
          utils.timesheetRouter.getMobileMonth.invalidate();
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toast({
            title: "Timeliste er låst!",
            description:
              "Timelisten for denne måneden er låst. Kanskje du mente å føre på en annen måned?",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Noe gikk galt!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      });
  }

  async function updateHourEntry(entry: TimeRegisterData) {
    if (!data) return;
    const params = {
      ...entry,
      company_id: selectedTeam,
      entryid: data.entryid,
    };

    authAxios
      .patch("/timesheet/user", params)
      .then((res) => {
        if (res.status === 204) {
          toast({
            title: "Timeføring oppdatert!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate(-1);
          utils.timesheetRouter.getDesktopMonth.invalidate();
          utils.timesheetRouter.getMobileMonth.invalidate();
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toast({
            title: "Timeliste er låst!",
            description:
              "Timelisten for denne måneden er låst. Kanskje du mente å føre på en annen måned?",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Noe gikk galt!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      });
  }

  const onSumbit: SubmitHandler<TimeRegisterData> = async (entry) => {
    if (data) {
      updateHourEntry(entry);
    } else {
      createNewHourEntry(entry);
    }
  };

  return (
    <>
      <VStack width={"95%"} alignItems={"flex-start"} margin={"auto"}>
        <Heading size={"sm"}>
          {data ? "Rediger timeføring" : "Ny timeføring"}
        </Heading>
        <FormRegister
          onSumbit={onSumbit}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          watch={watch}
        />
        <Button
          isLoading={isSubmitting}
          w={"100%"}
          type="submit"
          form="registerHoursForm"
        >
          {data ? "Oppdater" : "Registrer"}
        </Button>
      </VStack>
    </>
  );
};
