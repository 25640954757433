import {
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { AvatarContainer } from "components";
import { useParams, useSearchParams } from "react-router-dom";
import { trpc } from "utils/trpc";
import { RoleForm } from "../components/company/employee/RoleForm";
import { SalaryForm } from "../components/company/employee/SalaryForm";
import { RemoveFromCompanyForm } from "../components/company/employee/RemoveFromCompanyForm";
import { ModuleLayout } from "layout/ModuleLayout";

export function EditEmployee() {
  const { user_id } = useParams();
  const [params, setParams] = useSearchParams();
  if (!user_id) throw new Error("No userid!");
  const { data: employee, error } =
    trpc.adminRouter.getEmployeeProfile.useQuery({
      user_id: user_id,
    });
  if (error) {
    console.log(error);
  }
  if (!employee) return null;
  const paramIdx = params.get("tabIdx");
  const currentIdx = paramIdx ? Number.parseInt(paramIdx) : 0;
  return (
    <ModuleLayout pageTitle={"Rediger ansatt"}>
      <Flex
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"start"}
      >
        <VStack w={"100%"} justifyContent={"center"}>
          <AvatarContainer
            src={employee.avatar_url ?? ""}
            size="xl"
            fallbackName={
              employee.first_name && employee.last_name
                ? `${employee.first_name} ${employee.last_name}`
                : ""
            }
          />

          <Heading size={"md"}>
            {employee.first_name} {employee.last_name}
          </Heading>
        </VStack>
        <Tabs variant="underline" w={"100%"} defaultIndex={currentIdx}>
          <TabList>
            <Tab onClick={() => setParams({ tabIdx: "0" })}>Rolle</Tab>
            <Tab onClick={() => setParams({ tabIdx: "1" })}>Lønn</Tab>
            {employee.role !== 0 && (
              <Tab onClick={() => setParams({ tabIdx: "2" })}>
                Fjern fra bedrift
              </Tab>
            )}
          </TabList>
          <TabIndicator />
          <TabPanels>
            <RoleForm currentRole={employee.role} user_id={employee.id} />
            <SalaryForm salary={employee.salary} user_id={user_id} />
            {employee.role !== 0 && (
              <RemoveFromCompanyForm
                user_id={user_id}
                name={employee.first_name ?? ""}
                email={employee.email ?? ""}
              />
            )}
          </TabPanels>
        </Tabs>
      </Flex>
    </ModuleLayout>
  );
}
