import { Avatar, Box, Heading, Text, VStack } from "@chakra-ui/react";
import { months } from "../../../../utils";
import { useSelectedMonth } from "hooks/useSelectedMonth";
import { RouterOutputs } from "utils/trpc";

export const ApproveHeader = ({
  employee,
}: {
  employee: RouterOutputs["adminRouter"]["getEmployees"][number];
}) => {
  const { month, year } = useSelectedMonth();
  return (
    <Box>
      <VStack gap={0}>
        <Avatar
          src={employee.avatar_url}
          name={`${employee.first_name} ${employee.last_name}`}
        />
        <VStack gap={0}>
          <Text>
            {employee.first_name} {employee.last_name}
          </Text>
          <Text>{employee.email}</Text>
        </VStack>
      </VStack>
      <Heading size={"sm"}>
        {months[month]} {year}
      </Heading>
    </Box>
  );
};
