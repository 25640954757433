import { RouterOutputs } from "./trpc";

export const getCurrentPayForEmployee = (
  salaries: NonNullable<
    RouterOutputs["userRouter"]["getUserProfile"]
  >["salary"],
  orgnumber: number,
  date: Date,
): number => {
  let current;

  for (let salary of salaries) {
    if (
      new Date(salary.validfrom).getTime() < date.getTime() &&
      Number(salary.organization) === orgnumber
    ) {
      current = salary;
    }
  }

  if (current !== undefined) {
    return current.hourlypay ?? 0;
  }
  return 0;
};
