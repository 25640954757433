import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEnheterQuery } from "../useEnheterQuery";
import { useDebounce } from "hooks/useDebounce";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { links, mod11SecurityDigitValid } from "utils";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
  Text,
  VStack,
  Heading,
  Button,
  HStack,
  Link,
} from "@chakra-ui/react";
import { getAllAdresses, getApproxCompanyAddress } from "../getAllAdresses";
import { RadioCard, RadioCardGroup } from "../components/RadioCardGroup";
import { Link as ReactRouterLink } from "react-router-dom";

const formSchema = z.object({
  organisasjonsnummer: z
    .string({ message: "Du må velge et alternativ!" })

    .refine((v) => mod11SecurityDigitValid(v, "ORGANIZATION_ID"), {
      message: "Organisasjonsnummeret var ikke gyldig!",
    }),
});

export const AutoFillOrganizationSchema = z.object({
  name: z.string(),
  organization_id: z
    .number()
    .refine((v) => mod11SecurityDigitValid(String(v), "ORGANIZATION_ID")),
  adresses: z.array(
    z.object({
      street_adress: z.string(),
      postal_code: z.string().refine((v) => v.length === 4),
      postal_place: z.string(),
      id: z.string().uuid(),
    }),
  ),
});

type FormValue = z.infer<typeof formSchema>;

export function CreateOrganizationAuto() {
  const [query, setQuery] = useState("");
  const toast = useToast();
  const debouncedValue = useDebounce(query, 250);
  const { data, fetchNextPage, hasNextPage, isFetched } =
    useEnheterQuery(debouncedValue);
  const navigate = useNavigate();
  const form = useForm<FormValue>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
  });

  async function onSubmit(value: FormValue) {
    if (!data?.pages) {
      toast({
        title: "Velg et alternativ først!",
        status: "warning",
      });
      return;
    }

    let enhet;
    // Search through pages for org.
    for (const page of data.pages) {
      enhet = page?._embedded.enheter.find(
        (e) => e.organisasjonsnummer === value.organisasjonsnummer,
      );
    }
    if (!enhet) {
      toast({
        title: "Du må velge et resultat",
        status: "warning",
      });
      return;
    }

    const adresses = getAllAdresses(enhet);

    const organization = {
      name: enhet.navn,
      organization_id: Number.parseInt(enhet.organisasjonsnummer),
      adresses: adresses,
    };

    const dataString = JSON.stringify(organization);
    localStorage.setItem("create-organization-parial-data", dataString);
    navigate(links.admin_onboarding_create_organization_manual);
  }
  console.log(data);
  console.log(isFetched);

  return (
    <VStack
      as="form"
      bg="gray.50"
      p={4}
      mt={2}
      gap={5}
      boxShadow="sm"
      borderRadius="lg"
      width={{ base: "95%", sm: "400px" }}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Box width={"100%"}>
        <Heading textAlign={"left"} size={"md"}>
          Finn organisasjon
        </Heading>
        {form.formState.errors.organisasjonsnummer && (
          <Text color={"red"}>
            {form.formState.errors.organisasjonsnummer.message}
          </Text>
        )}
      </Box>
      <FormControl>
        <FormLabel>Organisasjonsnavn</FormLabel>
        <Input variant="outline" onChange={(e) => setQuery(e.target.value)} />
        <FormHelperText>Søk på organisasjonsnavn</FormHelperText>
      </FormControl>
      <Box width={"100%"}>
        <Controller
          control={form.control}
          name="organisasjonsnummer"
          render={({ field }) => (
            <RadioCardGroup
              value={field.value}
              onChange={field.onChange}
              spacing="3"
            >
              {data?.pages.map((page) =>
                page?._embedded?.enheter.map((enhet) => {
                  const companyApproxAdress = getApproxCompanyAddress(enhet);
                  return (
                    <RadioCard value={enhet.organisasjonsnummer}>
                      <Text
                        color="fg.emphasized"
                        fontWeight="medium"
                        fontSize="sm"
                      >
                        {enhet.navn}
                      </Text>
                      <Text color="fg.muted" textStyle="sm">
                        {companyApproxAdress?.street_address &&
                          companyApproxAdress.street_address + ","}
                        {companyApproxAdress?.postal_code},{" "}
                        {companyApproxAdress?.postal_place}
                      </Text>
                    </RadioCard>
                  );
                }),
              )}
              {}
            </RadioCardGroup>
          )}
        />
        {data?.pages && !data.pages[0]?._embedded && query !== "" && (
          <Text textAlign={"center"}>Ingen resultater 😕</Text>
        )}
      </Box>
      {hasNextPage && (
        <Button onClick={() => fetchNextPage()} variant={"ghost"}>
          Flere resultater?
        </Button>
      )}
      <HStack justify={"space-between"} w={"100%"}>
        <Link
          as={ReactRouterLink}
          to={links.admin_onboarding_create_organization_manual}
        >
          Fylle ut manuelt?
        </Link>
        <Button type="submit">Fortsett</Button>
      </HStack>
    </VStack>
  );
}
