import { Divider, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { trpc } from "utils/trpc";
import { DetailBanner } from "../components/detail/DetailBanner";

export function UtleggDetail() {
  const { attachmentId } = useParams();
  if (!attachmentId) throw new Error("No attachment ID!");
  const { data } = trpc.bilagRouter.getBilagDetail.useQuery({
    attachmentId: attachmentId,
  });

  if (!data) return null;

  return (
    <VStack w={"100%"} p={10}>
      <DetailBanner
        organizationName={
          (data.attachment.organization && data.attachment.organization.name) ??
          ""
        }
        organizationAvatarUrl={
          (data.attachment.organization &&
            data.attachment.organization.avatar_url) ??
          ""
        }
        attachmentId={data.attachment.id}
        attachmentTitle={data.attachment.title}
      />
      <Divider />
      {data.urls.map((url: any) => {
        return (
          <object
            aria-label="Utlegg vedlegg"
            data={url.signedUrl}
            width="100%"
            height="800"
          ></object>
        );
      })}
    </VStack>
  );
}
